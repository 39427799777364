import { Box } from "@otrium/core";
import { Skeleton } from "src/atoms/Skeleton";

const BrandCoverSkeleton = (): JSX.Element => (
  <Box
    sx={{
      height: "480px",
      width: "100%",
    }}
  >
    <Skeleton />
  </Box>
);

export default BrandCoverSkeleton;
