import { FC, ReactNode, useEffect, useState } from "react";
import {
  StyledBannerImg,
  StyledBrandCoverContainer,
  StyledLogoImg,
} from "./BrandCover.styled";
import { HomePageSingleBrand } from "src/types/graphql.d";
import { Box, Flex } from "@otrium/core";
import { useImageError } from "src/hooks/useImageError";
import { ProductLabels } from "src/molecules/ProductLabels";
import { Content } from "src/modules/brands/BrandView.styled";
import { AlgoliaSearchBrand, Gender } from "src/types/Algolia";
import { Heading } from "@otrium/atoms";
import { LockIcon } from "@otrium/icons";
import { useTheme, Theme } from "@emotion/react";

interface Props {
  brand: HomePageSingleBrand | AlgoliaSearchBrand;
  genderId: Gender | string;
  isComingSoon: boolean;
  actions: ReactNode;
  minimizeHeightOnMobile?: boolean;
  disablePaddingSM?: boolean;
  isGated?: boolean;
  isMobile?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const BrandCover: FC<Props> = ({
  brand,
  genderId,
  isComingSoon,
  actions,
  minimizeHeightOnMobile = true,
  disablePaddingSM,
  isGated,
  isMobile,
  onMouseEnter,
  onMouseLeave,
}) => {
  const [setLogoImg, hasLogoImgError] = useImageError();
  const [setBackgroundImg, hasBackgroundImgError] = useImageError();
  const [coverImage, setCoverImage] = useState<string | undefined>();
  const theme: Theme = useTheme();

  useEffect(() => {
    // TODO: cleanup after the migration https://otrium.atlassian.net/browse/SHOP-2336
    // NOTE: If it's 'HomePageSingleBrand' type
    const oldCoverImages = "image" in brand ? brand.image : "";
    const image =
      "images" in brand && brand.images[genderId as Gender]
        ? brand.images[genderId as Gender].find(
            ({ device }) => device === (isMobile ? "mobile" : "desktop")
          )?.url
        : oldCoverImages;
    setCoverImage(image);
  }, [brand, genderId, isMobile]);

  // TODO: cleanup after the migration https://otrium.atlassian.net/browse/SHOP-2336
  const oldOverlayLogo = "overlay_logo" in brand ? brand.overlay_logo : "";
  const overlayLogo =
    "overlay_logo_images" in brand && brand.overlay_logo_images.length > 0
      ? brand.overlay_logo_images[0].url
      : brand.logo;

  const brandLogo =
    "overlay_logo_images" in brand ? overlayLogo : oldOverlayLogo;

  // TODO: cleanup after the migration https://otrium.atlassian.net/browse/SHOP-2336
  const labelsByGender =
    "cover_images" in brand
      ? brand.labels[genderId as Gender].slice(0, 2)
      : brand.labels?.slice(0, 2);

  return (
    <Content disablePaddingSM={disablePaddingSM}>
      <StyledBrandCoverContainer
        minimizeHeightOnMobile={minimizeHeightOnMobile}
        isGated={isGated}
        isMobile={isMobile}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {!hasBackgroundImgError && coverImage && (
          <StyledBannerImg
            ref={setBackgroundImg}
            src={coverImage}
            alt={`${brand.name} brand cover`}
            className="brand-cover"
          />
        )}

        {isGated && (
          <Box
            sx={{
              position: "absolute",
              right: "16px",
              top: "16px",
              zIndex: 1,
            }}
          >
            <LockIcon color={theme.colors.tone.white} />
          </Box>
        )}

        <Flex
          flexWrap="wrap"
          justifyContent="center"
          alignSelf="center"
          alignItems="center"
          sx={{
            position: "absolute",
            zIndex: 12,
            left: "50%",
            bottom: "0",
            top: "0",
            transform: "translate(-50%, 0)",
          }}
        >
          {!hasLogoImgError && brandLogo ? (
            <Box sx={{ minWidth: "300px", height: "100%" }}>
              <StyledLogoImg
                ref={setLogoImg}
                src={brandLogo}
                alt={brand.name}
              />
            </Box>
          ) : (
            brand.name && (
              <Heading
                as="h2"
                variant="h1"
                color={"white"}
                sx={{ textTransform: "uppercase", fontFamily: "body" }}
              >
                {brand.name}
              </Heading>
            )
          )}
        </Flex>
        <Flex
          sx={{
            position: "absolute",
            left: "50%",
            bottom: ["24px", "24px", "24px", "48px", "48px"],
            transform: "translate(-50%,0)",
            zIndex: 123,
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            sx={{ flexFlow: "column" }}
          >
            <Flex wrap="nowrap" mb={3}>
              {actions}
            </Flex>

            {!isGated && labelsByGender && !isComingSoon && (
              <Flex pt={1} mx={0} pr={8} justifyContent="center">
                <ProductLabels labels={labelsByGender} />
              </Flex>
            )}
          </Flex>
        </Flex>
      </StyledBrandCoverContainer>
    </Content>
  );
};

export default BrandCover;
