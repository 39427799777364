import { getI18nRoute } from "src/utils/i18nRoutes";
import { ShopType } from "src/types/graphql.d";
import { Locale } from "lib/Intl";

const getAllBrandsPageRoute = ({
  shopType,
  locale,
}: {
  shopType: ShopType;
  locale: Locale;
}): string => {
  return getI18nRoute({
    route: `/${shopType}/brands`,
    locale,
  });
};

export default getAllBrandsPageRoute;
