import { ImageBannerData, PromotionDisplayType } from "src/types/graphql.d";

export function filterExpiredImageBannerDeals(
  brands: ImageBannerData[]
): ImageBannerData[] {
  if (!brands || brands.length === 0) {
    return [];
  }

  return brands.filter((item) => {
    const endDate = new Date(item.end_date);

    return item.display_type === PromotionDisplayType.LightningDeal
      ? endDate.getTime() - 1000 > Date.now()
      : true;
  });
}
