import styled from "@emotion/styled";
import { Box } from "@otrium/core";
import { Content as AtomContent } from "src/atoms/Content";

const Content = styled(AtomContent)<{ disablePaddingSM?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${({ theme }) => theme.space.space32};

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${({ disablePaddingSM }) => disablePaddingSM && "padding: 0 0"};
  }
`;

const StyledBrandView = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  place-content: center;
  row-gap: ${({ theme }) => theme.space.space32};

  .BrandView__catalogNavigation {
    margin-top: 16px;
  }

  .BrandView__productsGrid {
    width: 100%;
  }

  .BrandView__productsSortAndPagination {
    margin-bottom: 32px;
    @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
      display: none;
    }
  }

  .BrandView_sortBySelect {
    margin-left: 8px;

    select {
      height: 40px;
    }
  }

  .BrandView_topPagination {
    > div {
      padding: 0;
    }
  }
`;

const IconicCarouselContainer = styled.div`
  h2 {
    text-transform: none;
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    h2 {
      font-size: ${({ theme }) => theme.fontSizes[4]}px;
    }
  }
`;

const MobileButtonFilter = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: none;
  }
`;

const BrandViewCatalog = styled(Box)`
  display: block;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 32px;
  @media (max-width: ${({ theme }) => theme.breakpoints[4]}) {
    grid-gap: 24px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: grid;
    margin: 32px 0px 0px;
  }
`;

const BrandViewProducts = styled(Box)<{ hideCategoryTree?: boolean }>`
  flex: 1;
  position: relative;
  grid-column: 2/5;
  padding-left: 2px;

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    grid-column: 2/5;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[3]}) {
    grid-column: 2/5;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-bottom: 96px;
    grid-column: ${({ hideCategoryTree }) =>
      hideCategoryTree ? "1/5" : "2/5"};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    grid-column: 1/5;
  }
`;

export {
  BrandViewCatalog,
  StyledBrandView,
  BrandViewProducts,
  MobileButtonFilter,
  Content,
  IconicCarouselContainer,
};
