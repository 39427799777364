import { FC, useCallback, useContext, useState } from "react";
import { AppCtx } from "src/contexts/app.context";
import {
  HomePageSingleBrand,
  ImageBannerData,
  SummaryBrand,
} from "src/types/graphql.d";
import { CarouselHeader } from "src/molecules/CarouselHeader";
import { Carousel } from "src/molecules/Carousel";
import { BrandCard } from "src/molecules/BrandCard";
import { Box, BoxProps, useBreakpoints } from "@otrium/core";
import { EmotionBaseProps as BaseProps } from "src/types/global";
import { useImageHeight } from "src/hooks/useImageHeight";
import SegmentBrandTracking from "src/atoms/SegmentTracking/SegmentBrandTracking";
import {
  Brand,
  BrandInjectedProps,
  SEGMENT_POSITIONS,
  SEGMENT_PROMOTION_TYPE,
} from "src/segment";
import { filterExpiredImageBannerDeals } from "src/utils/filterExpiredImageBannerDeals";

interface BrandCarouselProps {
  title?: string;
  subTitle?: string;
  headerLinkHref?: string;
  headerLinkAs?: string;
  brands: Brand[] | null;
  genderId: string;
  slidesDesktop?: number;
  slidesTablet?: number;
  slidesMobile?: number;
  orderByNew?: boolean;
  associatedCategoryId?: string | null;
  disableTranslation?: boolean;
  slideShadowSpace?: number;
  shouldReset?: boolean;
  tab?: string;
  hasVerticalPadding?: boolean;
  promotionType?: SEGMENT_PROMOTION_TYPE;
}

const BrandCarousel: FC<BoxProps & BaseProps & BrandCarouselProps> = ({
  title,
  subTitle,
  headerLinkHref,
  headerLinkAs,
  brands,
  genderId,
  slidesDesktop,
  slidesTablet,
  slidesMobile,
  shouldReset,
  orderByNew = false,
  associatedCategoryId,
  disableTranslation = false,
  slideShadowSpace = 48,
  tab,
  hasVerticalPadding = true,
  promotionType,
  ...rest
}) => {
  const { locale } = useContext(AppCtx);
  const { isMobile } = useBreakpoints();
  const [imageElementWrapperHeight, getImageElementWrapper] = useImageHeight();
  const filteredBrands = filterExpiredImageBannerDeals(
    brands as ImageBannerData[]
  );
  const [brandsData, setBrandsData] = useState<Brand[]>(
    filteredBrands.length > 3 &&
      promotionType === SEGMENT_PROMOTION_TYPE.imageBanners
      ? filteredBrands.slice(0, 3)
      : filteredBrands || []
  );

  const getPosition = (brand: Brand, index: number) => {
    const { __typename: typeName } = brand;
    const isImageBanner = typeName === "ImageBannerData";

    return `${
      isImageBanner ? SEGMENT_POSITIONS.curated : SEGMENT_POSITIONS.carousel
    }-${title || ("title" in brand ? brand.title : "")}-${index + 1}`;
  };

  const handleTimerExpired = useCallback(
    (uuid: string) => {
      const newBrandsData =
        filterExpiredImageBannerDeals(brands as ImageBannerData[]).filter(
          (item) => item?.block_uuid !== uuid
        ) || [];

      setBrandsData(
        newBrandsData.length > 3
          ? newBrandsData.slice(0, 3)
          : newBrandsData.length < 3
          ? []
          : newBrandsData
      );
    },
    [brands]
  );

  if (
    promotionType === SEGMENT_PROMOTION_TYPE.imageBanners &&
    brandsData.length < 3
  ) {
    return null;
  }

  return (
    <Box as="section" {...rest}>
      {(title || subTitle) && (
        <CarouselHeader
          title={title}
          subTitle={subTitle}
          href={headerLinkHref}
          as={headerLinkAs}
          prefetch={false}
        />
      )}
      <Carousel
        shouldReset={shouldReset}
        slideShadowSpace={slideShadowSpace}
        isProductCarousel={false}
        slidesDesktop={slidesDesktop}
        slidesTablet={slidesTablet}
        slidesMobile={slidesMobile}
        marginSize={isMobile ? "m" : "l"}
        imageElementWrapperHeight={imageElementWrapperHeight}
        hasVerticalPadding={hasVerticalPadding}
        items={brandsData.map((brand, index) => {
          const { id } = brand as SummaryBrand | HomePageSingleBrand;
          const brandData = brand as ImageBannerData;

          return (
            <SegmentBrandTracking
              brand={brand}
              key={id || brandData?.block_uuid}
              position={getPosition(brand, index)}
              tab={tab}
              promotionType={promotionType}
            >
              {({ onClickHandler }: BrandInjectedProps) => (
                <BrandCard
                  associatedCategoryId={associatedCategoryId}
                  brand={brand}
                  genderId={genderId}
                  getImageElementWrapper={getImageElementWrapper}
                  locale={locale}
                  orderByNew={orderByNew}
                  onClickHandler={onClickHandler}
                  disableTranslation={disableTranslation}
                  brandType={promotionType}
                  refetchQueriesOnDeleteWishListBrand={[
                    "wishlistBrands",
                    "userWishlistMetaData",
                  ]}
                  position={getPosition(brand, index)}
                  tab={tab}
                  handleTimerExpired={handleTimerExpired}
                />
              )}
            </SegmentBrandTracking>
          );
        })}
      />
    </Box>
  );
};

export default BrandCarousel;
