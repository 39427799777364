import { JSX } from "react";
import { Text } from "@otrium/atoms";
import { Box } from "@otrium/core";
import { Trans } from "@lingui/macro";
import { Content } from "src/atoms/Content";

const RRPFootnote = (): JSX.Element => {
  return (
    <Box backgroundColor="tone.marble" paddingY="space16">
      <Content>
        <Text as="p" textAlign="center">
          *&nbsp;
          <Trans>footer.rrp_note</Trans>
        </Text>
      </Content>
    </Box>
  );
};

export default RRPFootnote;
