import styled from "@emotion/styled";
import { Skeleton } from "src/atoms/Skeleton";

const StyledBrandCoverContainer = styled.div<{
  minimizeHeightOnMobile: boolean;
  isGated?: boolean;
  isMobile?: boolean;
}>`
  position: relative;
  height: 480px;
  width: 100%;
  background: ${(props) => props.theme.colors.tone.silver};
  cursor: pointer;

  :before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.6;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
  }

  :hover {
    .brand-cover {
      filter: ${({ isGated, isMobile }) =>
        !isMobile && isGated ? "blur(16px)" : "none"};
    }
    .login-btn {
      background: ${(props) =>
        !props.isMobile && props.theme.colors.tone.white};
      color: ${(props) => !props.isMobile && props.theme.colors.tone.black};
    }
  }

  ${({ minimizeHeightOnMobile, theme }) =>
    minimizeHeightOnMobile &&
    `
      @media (max-width: ${theme.breakpoints[2]}) {
        height: 300px;
      }
    `}
`;

const StyledBannerImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledLogoImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export {
  StyledBrandCoverContainer,
  StyledBannerImg,
  StyledSkeleton,
  StyledLogoImg,
};
